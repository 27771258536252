import NavigationBar from "../components/navigationBar";
import Layout from "../layout";

const User = () => {
  return (
    <Layout>
      <div className="relative w-full h-full">
        <NavigationBar />
      </div>
    </Layout>
  );
};

export default User;
