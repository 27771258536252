import Layout from "../layout";

const NotFound = () => {
  return (
    <Layout>
      <div>
        <div>404</div>
        <div>존재하지 않는 페이지입니다.</div>
      </div>
      <div>
        <button>이전 페이지</button>
        <button>메인 페이지</button>
      </div>
    </Layout>
  );
};

export default NotFound;
